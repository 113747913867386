<template>
  <v-container class="ak-page-container">
    <PageTitle title="Ranking" :subtitle="userClass" />

    <v-row no-gutters class="ak-user-current" justify="start" align="center">
      <v-col cols="2">
        <AKAvatar
          class="ak-user-photo"
          :avatarHeight="48"
          :avatarWidth="48"
          :avatar="userInfo.avatar"
        />
      </v-col>
      <v-col cols="1" class="ak-user-place font22-600">
        {{ userInfo.ranking }}.
      </v-col>
      <v-col cols="6" class="ak-user-name font22-600">
        {{ userInfo.nickname }}
      </v-col>
      <v-col cols="3" class="ak-user-point font22-600 text-right pr-3">
        {{ userInfo.score }}
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-end">
        <p class="ak-colorText-sandWhite font-roboto font16-400 mb-3 mr-6">
          Punkte
        </p>
      </v-col>

      <v-col cols="12" class="ak-users-list" @scroll="isScrolled">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row
              v-for="(item, index) in usersList"
              no-gutters
              class="ak-user-info"
              :class="{ 'font16-600': userInfo.ranking === item.ranking }"
              justify="start"
              align="center"
              :key="index"
            >
              <v-col cols="2" class="px-2">
                <AKAvatar
                  class="ak-user-photo"
                  :avatarHeight="32"
                  :avatarWidth="32"
                  :avatar="item.avatar"
                />
              </v-col>
              <v-col cols="1" class="font16-400"> {{ item.ranking }}. </v-col>
              <v-col cols="6" class="font16-400">
                {{ item.nickname }}
              </v-col>
              <v-col cols="3" class="font16-600 text-right pr-3">
                {{ item.score }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <template v-if="showMore">
        <div class="show-more" @click="scrollToBottom">
          <mdicon name="ChevronDown" size="22" />
        </div>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import AKAvatar from '@/components/common/core/AKAvatar';
import PageTitle from '@/components/common/PageTitle';

export default {
  name: 'Ranking',
  components: { PageTitle, AKAvatar },

  data() {
    return {
      showMore: false,
      usersList: null,
      userClass: null,
    };
  },

  created() {
    this.$axios
      .get(`/api/v1/user/scoreboard`, this.axiosGetConfig)
      .then((res) => {
        if (res.status === 200) {
          this.userClass = res.data.data.group;
          this.usersList = res.data.data.users;
          this.setBlockSize();
        }

        this.$forceUpdate();
      });
  },

  methods: {
    setBlockSize() {
      let divOne = document.getElementsByClassName('ak-page-container')[0];
      let divTwo = document.querySelectorAll('.ak-users-list .ak-user-info')[0];

      if (46 * this.usersList.length > divOne.offsetHeight) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },

    isScrolled() {
      let divOne = document.getElementsByClassName('ak-users-list')[0];
      let divTwo = document.querySelectorAll('.ak-users-list .ak-user-info')[0];

      if (
        divOne.scrollTop <
        (divTwo.offsetHeight + 14) * (this.usersList.length - 5) -
          divOne.offsetHeight / 2
      ) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },

    scrollToBottom() {
      let divOne = document.getElementsByClassName('ak-users-list')[0];
      divOne.scrollTo({ top: divOne.scrollHeight, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-user {
  &-current,
  &-info {
    width: 100%;
    background: var(--v-secondary-base);
    border-radius: 10px;
    padding: 18px 11px;
    color: var(--v-fontGrey-base);
    margin-bottom: 12px;
  }

  &-point {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
  }

  &-name {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
  }
}

.ak-users-list {
  background: var(--v-secondary-base);
  border-radius: 10px;
  padding: 18px 11px !important;
  max-height: 60vh;
  overflow: scroll;
  position: relative;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .ak-user-info {
    margin-bottom: 14px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.show-more {
  position: absolute;
  bottom: 30px;
  width: 40px;
  margin: 0 auto 10px;
  color: #000;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  align-content: center;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
  border-radius: 50px;
  justify-content: center;
  z-index: 1;
  background: #fff;
  cursor: pointer;
}
</style>
